var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"extended-forms","fluid":"","tag":"section"}},[_c('base-v-component',{attrs:{"heading":"Date Pickers","link":"components/date-pickers"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('base-material-card',{attrs:{"color":"success","icon":"mdi-calendar-today"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 mt-2 font-weight-light"},[_vm._v(" Date Picker "),_c('span',{staticClass:"body-1"},[_vm._v("— labeled")])])]},proxy:true}])},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","full-width":"","min-width":"290px","offset-y":""},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"color":"secondary","label":"Select date","prepend-icon":"mdi-calendar-outline","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"secondary","landscape":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","large":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"secondary","large":""},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('base-material-card',{attrs:{"color":"success","icon":"mdi-calendar-today"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 mt-2 font-weight-light"},[_vm._v(" Date Picker "),_c('span',{staticClass:"body-1"},[_vm._v("— date selected")])])]},proxy:true}])},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","full-width":"","min-width":"290px","offset-y":""},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"color":"secondary","label":"Select date","prepend-icon":"mdi-calendar-outline","readonly":""},model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"secondary","landscape":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","large":""},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('base-material-card',{attrs:{"color":"success","icon":"mdi-calendar-today"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 mt-2 font-weight-light"},[_vm._v(" Date Picker "),_c('span',{staticClass:"body-1"},[_vm._v("— close on select")])])]},proxy:true}])},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","full-width":"","min-width":"290px","offset-y":""},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"color":"secondary","prepend-icon":"mdi-calendar-outline","readonly":""},model:{value:(_vm.date3),callback:function ($$v) {_vm.date3=$$v},expression:"date3"}},on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"color":"secondary","landscape":"","scrollable":""},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.date3),callback:function ($$v) {_vm.date3=$$v},expression:"date3"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","large":""},on:{"click":function($event){_vm.menu3 = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-subheading',[_vm._v(" Switches ")]),_c('v-switch',{attrs:{"hide-details":"","label":"Toggle is on","input-value":"true"}}),_c('v-switch',{attrs:{"hide-details":"","label":"Toggle is off"}}),_c('div',{staticClass:"my-6"}),_c('base-subheading',[_vm._v(" Tags ")]),_c('v-combobox',{attrs:{"color":"secondary","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"secondary","close":"","small":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),_c('div',{staticClass:"my-6"}),_c('base-subheading',{staticClass:"mb-6"},[_vm._v(" Progress Bar ")]),_c('v-progress-linear',{attrs:{"color":"secondary","value":30}}),_c('div',{staticClass:"my-6"}),_c('v-progress-linear',{attrs:{"color":"info","value":60}}),_c('div',{staticClass:"my-6"}),_c('v-progress-linear',{attrs:{"value":40,"color":"warning","buffer-value":"0","stream":""}}),_c('div',{staticClass:"my-6"}),_c('base-subheading',{staticClass:"mb-6"},[_vm._v(" File Input ")]),_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"File input","multiple":"","placeholder":"Select your files","prepend-icon":"mdi-paperclip","outlined":"","display-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-subheading',[_vm._v(" Customizable Select ")]),_c('v-select',{attrs:{"color":"secondary","item-color":"secondary","label":"Movie","items":_vm.movies},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])}),_c('v-select',{attrs:{"color":"secondary","item-color":"secondary","label":"Movie","multiple":"","items":_vm.movies},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-2 v-sheet",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1),_c('v-scale-transition',[(attrs.inputValue)?_c('v-list-item-icon',{staticClass:"my-3"},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1)],1)]}}])}),_c('div',{staticClass:"my-3"}),_c('base-subheading',{staticClass:"mb-6"},[_vm._v(" Dropdown & Dropup ")]),_c('v-row',{staticClass:"mb-6",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('base-material-dropdown',{attrs:{"items":_vm.dropdown,"color":"success","default":""}},[_vm._v(" MultiLevel Dropdown ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('base-material-dropdown',{attrs:{"items":_vm.dropdown,"color":"success","origin":"top right"}},[_vm._v(" Dropdown ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('base-material-dropdown',{attrs:{"items":_vm.dropdown,"color":"success","origin":"bottom right","top":""}},[_vm._v(" Dropup ")])],1)],1),_c('base-subheading',[_vm._v(" Sliders ")]),_c('v-slider',{attrs:{"max":_vm.max,"min":_vm.min,"color":"secondary"},model:{value:(_vm.slider),callback:function ($$v) {_vm.slider=$$v},expression:"slider"}}),_c('v-range-slider',{attrs:{"color":"info","track-color":"info lighten-4"},model:{value:(_vm.slider2),callback:function ($$v) {_vm.slider2=$$v},expression:"slider2"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }